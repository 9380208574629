import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui 英文包
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui 中文包
// 自定义的中英文配置
import enLocale from "./components/language/en";
import zhLocale from "./components/language/zh";

import scroll from "vue-seamless-scroll";
import LangSelect from "@/components/LangSelect";
import '@/permission' // permission control
// import {translate} from './utils/translate'
// translate.setUseVersion2() //设置使用v2.x 版本
// translate.selectLanguageTag.show = false //是否显示切换栏
// translate.listener.start()
// Vue.prototype.$translate = translate
//中英文切换
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)
const i18n = new VueI18n({
  locale: localStorage.getItem("languageSet") || "zh", //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    // 英文环境下的语言数据
    en: {
      ...enLocale,
      ...elementEnLocale,
    },
    // 中文环境下的语言数据
    zh: {
      ...zhLocale,
      ...elementZhLocale,
    },
  },
});
Vue.component("lang-select", LangSelect);
Vue.config.productionTip = false;
// Vue.use(ElementUI).use(scroll);
Vue.use(scroll)
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  store,
  i18n, //把 i18n 挂载到 vue 根实例上
  render: (h) => h(App),
}).$mount("#app");
