import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import axios from "axios";
import { Message } from "element-ui";
const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://guhring-equipment-bridge.chuilian-tech.cn/users/login",
          {
            username: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          // 处理响应数据
          // console.log(response);
          if (response.data.code == 200) {
            commit("SET_TOKEN", response.data.token);
            // commit('SET_NAME', 'admin')
            setToken(response.data.token);
            resolve();
          } else {
            Message({
              message: response.data.msg || "Error",
              type: "error",
              duration: 5 * 1000,
            });
            reject(error);
          }
        })
        .catch(function (error) {
          // 处理错误情况
          console.error("Error:", error);
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      // getInfo(state.token).then(res => {
      // console.log(res);
      // const user = res.user
      // const avatar = (user.avatar == "" || user.avatar == null) ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : process.env.VUE_APP_BASE_API + user.avatar;
      // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
      //   commit('SET_ROLES', res.roles)
      //   commit('SET_PERMISSIONS', res.permissions)
      // } else {
      //   commit('SET_ROLES', ['ROLE_DEFAULT'])
      // }
      // commit('SET_NAME', user.userName)
      // commit('SET_AVATAR', avatar)
      // resolve(res)
      // }).catch(error => {
      // reject(error)
      // })
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      removeToken(); // must remove  token  first
      // resetRouter()
      commit("RESET_STATE");
      commit("SET_TOKEN", "");
      // commit('SET_ROLES', [])
      // commit('SET_PERMISSIONS', [])
      // location.reload(); // 刷新
      resolve();
      // }).catch(error => {
      //   reject(error)
      // })
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      commit("SET_TOKEN", "");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
