<template>
  <el-dropdown
    trigger="click"
    class="international"
    @command="handleSetLanguage"
  >
    <div>
      {{ language == "zh" ? "中文" : "English" }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language === 'zh'" command="zh">
        中文
      </el-dropdown-item>
      <el-dropdown-item :disabled="language === 'en'" command="en">
        English
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data() {
    return {
      // language:localStorage.getItem('languageSet')||'zh'
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    handleSetLanguage(lang) {
      // console.log(lang);
      // var iframe = document.getElementById("weather");
      // var tha = this;
      // this.$translate.request.translateText(
      //   this.$i18n.locale == "zh" ? "chinese_simplified" : "english",
      //   lang == "zh" ? "chinese_simplified" : "english",
      //   iframe.innerText,
      //   function (data) {
      //     //打印翻译结果
      //     if (data.result == 1) {
      //       console.log(data);
      //       iframe.innerText = data.text[0];
      //     }
      //   }
      // );
      this.$i18n.locale = lang;
      localStorage.setItem("languageSet", this.$i18n.locale);
      // this.language=lang
      // this.$store.dispatch('app/setLanguage', lang)
      // this.$message({
      //   message: 'Switch Language Success',
      //   type: 'success'
      // })
    },
  },
};
</script>
